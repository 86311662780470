import { get } from '@/utils/http.js';

/*
function:获取答题报告
author:ysl
time:2021/03/22
*/

export function getAnswerReport(data: any) {
    return get('/answer/mobile/management', data);
}
