




















import { Component, Vue } from 'vue-property-decorator';
import myHeader from '@/components/Header.vue';
import report from '@/components/Report.vue';
import { getAnswerReport } from '@/api/courseWork/myHomework/answerReport.ts';

@Component({
    components: {
        myHeader,
        report
    }
})
export default class AnswerReport extends Vue {
    data = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        ans_id: 0
    };

    /*
    * 组件数据
    * reportData:题目列表值 reportStyle:组件形态
    */
    reportData = [{
        reportData: [],
        reportStyle: {
            title: '答对题数',
            className: ''
        }
    }, {
        reportData: [1],
        reportStyle: {
            title: '答错题数',
            className: 'report-red'
        }
    }, {
        reportData: [],
        reportStyle: {
            title: '未答题数',
            className: 'report-grey'
        }
    }];

    // 课程名字
    crsName = '';
    // 节名字
    sectionName = '';
    // 题目总数
    total = 0;

    mounted() {
        this.getData();
    }

    // 方法
    getData() {
        // eslint-disable-next-line @typescript-eslint/camelcase
        this.data.ans_id = parseInt(this.$route.query.ansId as string);
        this.sectionName = this.$route.query.sectionName as string;
        this.crsName = this.$route.query.crsName as string;
        getAnswerReport(this.data).then((res: any) => {
            console.log(res);
            // eslint-disable-next-line @typescript-eslint/camelcase
            this.total = res.data.total_num;
            this.reportData[0].reportData = res.correct_data;
            this.reportData[1].reportData = res.error_data;
            this.reportData[2].reportData = res.no_ans_data;
        }).catch((error: any) => {
            console.log(error);
        });
    }

    // 跳转到查看解析
    goAnswerAnalysis() {
        this.$router.push({
            path: '/answerAnalysis',
            query: {
                crsName: this.crsName,
                sectionName: this.sectionName,
                ansId: this.data.ans_id.toString(),
                crsId: this.$route.query.crsId as string,
                totalQues: this.$route.query.totalQues as string
            }
        });
    }

    // 跳转到我的作业
    goMyHomeWork() {
        this.$router.push({
            path: '/myHomework',
            query: {
                crsName: this.$route.query.crsName as string,
                crsId: this.$route.query.crsId as string,
                totalQues: this.$route.query.totalQues as string
            }
        });
    }
}
