

















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Report extends Vue {
    /*
    * reportData:循环数据
    */
    @Prop({
        type: Array,
        required: true,
        default: () => ({})
    })
    reportData!: Object;

    /*
    * title:标题名字
    * className组件形态类型名字 report-red report-grey 不传:默认蓝色
     */
    @Prop({
        type: Object,
        required: false,
        default: () => ({})
    })
    reportStyle!: Object;
}
